<script>
  import { FBUser, loginWithUserPass } from "./Firebase.js";
  import Footer from "./Footer.svelte";
  let isLoading = false;
  let success = false;
  let email = "";
  let password = "";

  // Importing and Initializing Variables here
  let emailError = null;
  let serverError = null;
  let passwordError = null;

  function resetErrors() {
    emailError = null;
    serverError = null;
    passwordError = null;
  }

  function handleSubmit(event) {
    event.preventDefault();
    resetErrors();

    isLoading = true;
    serverError = null;
    success = false;

    // Basic Validation
    if (!email || !password) {
      serverError = "Please fill out all fields.";
      isLoading = false;
      return;
    }

    // Validate Email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      emailError =
      "Email must be in the format: name" + "@" + "domain" + "." + "com";
      isLoading = false;
      return;
    }

    loginWithUserPass(email, password)
    .then((data) => {
      console.log(FBUser);
      success = true;
    })
    .catch((error) => {
      if (error.message.includes('auth/invalid-login-credentials')) {
        serverError = 'Invalid credentials.';
      } else {
        serverError = 'An unexpected error occurred. Please try again later.';
      }
      console.error(error);
    })
    .finally(() => {
      isLoading = false;
    });
  }
</script>

<div class="full-height">
  <div class="narrow-container-centered navbar-padding">
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <div class="card p-4">
          <div class="card-body">
            <h2 class="card-title poppins">Login</h2>
            <form>
              <!-- Email Input -->
              <div class="poppins form-group">
                <label for="email">Email</label>
                <input
                type="email"
                class="form-control"
                id="email"
                bind:value={email}
                required
                />
                {#if emailError}<div class="text-danger">{emailError}</div>{/if}
              </div>

              <!-- Password Input -->
              <div class=" poppins form-group">
                <label for="pass">Password</label>
                <input
                type="password"
                class="form-control"
                id="pass"
                bind:value={password}
                required
                />
                <small class="poppins form-text text-muted"
                >Must be at least 8 characters long and contain: one uppercase
                letter, one lowercase letter, one number, and one special
                character.</small
                >
                {#if passwordError}<div class="text-danger">
                  {passwordError}
                </div>{/if}
              </div>

              <!-- Forgot Password Link -->
              <div>
                <a class="poppins black-text" href="/password_recovery">Forgot your password?</a>
              </div>

              <!-- Submit Button -->
              <button type="click" class=" poppins primary-button mt-3" on:click={handleSubmit}
              >Login</button
              >
            </form>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-8 offset-md-2">
            {#if isLoading}
            <div class="spinner-border text-primary" role="status">
              <span class="sr-only" />
            </div>
            {/if}
            {#if success}
            <div class="alert alert-success" role="alert">
              You have successfully logged in! If you are not redirected to the
              home page, click <a href="/">here</a>.
            </div>
            {/if}
            {#if serverError}
            <div class="text-danger">Error: {serverError}</div>
            {/if}
          </div>
        </div>
      </div>
    </div>
  </div>

  <Footer/>
</div>

<style>
  .form-group {
    margin-bottom: 20px; /* Add more space between the rows */
    margin-top: 2rem;
  }
</style>

