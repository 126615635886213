<script>
  import { user } from './Firebase.js';
  import Footer from "./Footer.svelte";
  import GetStartedButton from "./GetStartedButton.svelte"
</script>

<div class="full-height gray-background">
  <div class="d-flex row vertically-aligned right-container navbar-padding blue-background">
    <!-- <div class="row"> -->
      <div class="col-md-7 order-md-2">
       <h2 class="featurette-heading large-text poppins gray-text bottom-space top-space">Who We Are</h2>
       <p class="poppins mt-3 small-text gray-text thin-text">
       KnewHomes is a Miami-based fintech company founded in 2023. We are on a mission to make homeownership easier and push you closer to your financial goals.</p>

       <p class="poppins mt-3 smaller-text gray-text thin-text">Our priority is to <b>empower homeowners</b> like you to get the most out of your home, so you can enjoy life to the fullest.</p>
     </div>
     <div class="col-md-5 order-md-2">
      <img class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto right-algin" 
      src="/images/house4.png" alt="Video Call" />
    </div>
    <!-- </div> -->
  </div>


  <div class="featurette container centered gray-background max-width-container ">
    <div class="row">
      <h1 class="featurette-heading poppins black-text bottom-space">Our Promise To You</h1>
      <div class="col-lg-4">
        <h5 class="fw-bold centered poppins black-text small-text">Opportunity</h5>
        <p class="centered poppins black-text bottom-space smaller-text">
          We give you the opportunity to access your home equity the way you want to access it, decoupled from federal interest rates. 
        </p>
      </div>
      <div class="col-lg-4">
        <h5 class="fw-bold centered poppins black-text small-text">Flexibility</h5>
        <p class="black-text centered poppins bottom-space smaller-text">It's your equity. Use it when you want it. Not when someone else tells you that you can. </p>
      </div>
      <div class="col-lg-4">
        <h5 class="fw-bold centered poppins black-text small-text ">Control</h5>
        <p class="black-text centered poppins bottom-space smaller-text">
          We give you the control to make smart choice for you and your household, without the headache of regular payments.
        </p>
      </div>
    </div>
  </div>

  <div class="d-flex row vertically-aligned left-container orange-background">
    <div class="col-md-7 order-md-2 left-padded">
      <h2 class="featurette-heading poppins black-text bottom-space top-space">At KnewHomes, <b>you're our top priority</b>.</h2> 
      <p  class="poppins mt-3 smaller-text black-text thin-text">We're not just a company; we're <b>real people</b> who understand homeownership. We believe in transparency, and we strive to be helpful and neighbors in your community.

        <p  class="poppins mt-3 smaller-text black-text thin-text">We are a small team of real estate experts, financial specialists, and engineering professionals who are dedicated to changing the way you access the equity in your home. We are your neighbors in this journey. <b>And we are here to help you.</b>
        </p>
        <GetStartedButton classes="tertiary-button black-text bottom-mobile-padding" text="Talk to Us Now"/>
      </div>
      <div class="col-md-5 order-md-1">
        <img class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" 
        src="/images/house2.png" alt="Video Call" />
      </div>
    </div>



    <Footer/>
  </div>

  <style>
    .right-algin{
      float:  right;
    }


    @media screen and (max-width: 600px) {
      .top-space{
        padding-top: 30px;
      }

      .left-padded {
        padding-left: 10%;
      }
    }
  </style>