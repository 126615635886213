
<script>
    import { FBUser, createAccount, writeToFirebase } from './Firebase.js'; //FBUser is a firebase user object
    import Footer from "./Footer.svelte";
    import { navigate } from "svelte-routing";
    let isLoading = false;
    let success = false;
    let firstName = "";
    let lastName = "";
    let email = "";
    let password = "";

    // Importing and Initializing Variables here
    let firstNameError = null;
    let lastNameError = null;
    let emailError = null;
    let serverError = null;
    let passwordError = null;

    function resetErrors() {
        firstNameError = null;
        lastNameError = null;
        emailError = null;
        serverError = null;
        passwordError = null;
    }


    function handleSubmit(event) {
        event.preventDefault();
        resetErrors();

        isLoading = true;
        serverError = null;
        success = false;

        // Basic Validation
        if(!firstName || !lastName || !email || !password) {

            serverError = 'All fields are required! Please fill out all fields.';
            isLoading = false;
            return;
        }

        // Validate First and Last Name
        const nameRegex = /^[a-zA-Z]+$/;
        if(!nameRegex.test(firstName)) {
            firstNameError = 'First name invalid';
            isLoading = false;
            return;
        }

        if(!nameRegex.test(lastName)) {
            lastNameError = 'Last name invalid';
            isLoading = false;
            return;
        }

        // Validate Email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if(!emailRegex.test(email)) {
            emailError = 'Email must be in the format: name' + '@' + 'domain' + '.' + 'com';
            isLoading = false;
            return;
        }

        // Validate Password
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
        if (!passwordRegex.test(password)) {
            passwordError = 'Password must be at least 8 characters long and contain at least: one uppercase letter, one lowercase letter, one number, and one special character';
            isLoading = false;
            return;
        }

        
        // Perform Firebase Row Addition
        createAccount({ firstName, lastName, email, password }) //short hand for {name: name, email: email, phone: phone, equity: equityPercentage}
        .then((userAuth) => {
            writeToFirebase("/private/" + FBUser.uid, {firstName, lastName}).then((data) => {
                success = true;
                    //navigate after 3 seconds
                    setTimeout(() => {
                        navigate("/");
                    }, 3000);
                }).catch((error) => {
                    serverError = error.message;
                });
            })
        .catch((error) => {
            serverError = error.message;
        })
        .finally(() => {
            isLoading = false;
        });
    }
</script>

<style>
    .form-group {
      margin-bottom: 20px; /* Add more space between the rows */
      margin-top: 2rem;
  }
</style>

<div class=" full-height">
    <div class="narrow-container-centered navbar-padding">
        <div class="row">
            <div class="col-md-8 offset-md-2">
                <div class="card p-4">
                    <div class="card-body ">
                        <h2 class="poppins card-title">Create Account</h2>
                        <form>
                            <div class="poppins form-group">
                                <label for="first">First Name</label>
                                <input type="text" class="form-control" id="first" bind:value={firstName} required>
                                {#if firstNameError}<div class="text-danger">{firstNameError}</div>{/if}
                            </div>
                            <div class="poppins form-group">
                                <label for="last">Last Name</label>
                                <input type="text" class="form-control" id="last" bind:value={lastName} required>
                                {#if lastNameError}<div class="text-danger">{lastNameError}</div>{/if}
                            </div>
                            <div class="poppins form-group">
                                <label for="email">Email</label>
                                <input type="email" class="form-control" id="email" bind:value={email} required>
                                {#if emailError}<div class="text-danger">{emailError}</div>{/if}
                            </div>
                            <div class="poppins form-group">
                                <label for="pass">Password</label>
                                <input type="password" class="form-control" id="pass"  bind:value={password} required>
                                <small class="form-text text-muted">Must be at least 8 characters long and contain: one uppercase letter, one lowercase letter, one number, and one special character.</small>
                                {#if passwordError}<div class="text-danger">{passwordError}</div>{/if}
                            </div>
                            <button type="click" class="primary-button" on:click={handleSubmit}>Create Account</button>     
                        </form>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-8 offset-md-2">
                        {#if isLoading}
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only"></span>
                        </div>
                        {/if}
                        {#if success}
                        <div class="poppins alert alert-success" role="alert">
                            You have successfully created an account! If you are not redirected to the home page, click <a href="/">here</a>. 
                        </div>
                        {/if}
                        {#if serverError}
                        <div class="poppins text-danger">Error: {serverError}</div>
                        {/if}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer/>
</div>

