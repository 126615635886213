<div class="poppins">
  <button class={classes} on:click={check_prereqs}>{text}</button>
</div>

<script>
  import { user } from './Firebase.js';
  import Swal from 'sweetalert2'
  export let text = "Get Started"
  export let classes = "primary-button"


  function check_prereqs(){
    Swal.fire({
      title: "<div class='poppins'>Do You:</div>",
      html: `<span class="poppins">
      <div>Own more than 40% equity in your home?</div><br><div>Have a debt to income ratio of less than 40%?</div><br><div>Have no additional liens on your property?</div> </span>
      `,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: '#5C6E91',
    }).then((result) => {
      if (result.value) {
        window.location.href = 'https://calendly.com/lee-2ms/30min'
      }else{
        Swal.fire({
          html: `<span class="poppins">It looks like <b>Knew</b>Homes might not be the best fit for you right now. Please come back when you meet the pre-qualification criteria.</span>
          `,
          focusConfirm: false,
          confirmButtonText: "Continue",
          confirmButtonColor: '#5C6E91',
        })
      }
    }); 
  }
</script>

<style>
  .swal2-confirm, .swal2-styled{
    background-color: #FFFFFF;
  }
</style>
