<script>
  // Import necessary Svelte functions or variables
  import { onMount } from 'svelte';
  import { ref, uploadBytesResumable, listAll, deleteObject, getDownloadURL, getMetadata } from 'firebase/storage';
  import { FBUserData, userData, user, FBStorage, FBUser } from './Firebase';

  // Dictionary of section headers mapped to an array of document uploads
  export let fileSections = {};
  export let userDocumentData;
  var uploadPercentage = {};
  var haveLoadedFiles = false;

  if (userDocumentData === undefined) {
    throw new Error('userDocumentData prop is required');
  }

  async function refreshFiles() {
    if (FBUser === null) {
      return false;
    }
    const userStorageRef = ref(FBStorage, `private/${FBUser.uid}`);
    try {
      // Get all the items (files) and prefixes (folders) under this storage reference
      const res = await listAll(userStorageRef);
      // Get download URLs for each file reference
      const urls = await Promise.all(res.items.map(file => getDownloadURL(file)));
      const metas = await Promise.all(res.items.map(file => getMetadata(file)));
      for (const fileKey in userDocumentData) {
        userDocumentData[fileKey]['download_url'] = undefined;
        userDocumentData[fileKey]['metadata'] = undefined;
        userDocumentData[fileKey]['file_name'] = undefined;
        userDocumentData[fileKey]['file_ref'] = undefined;
      }
      res.items.forEach((file, index) => {
        for (const fileKey in userDocumentData) {
          if (fileKey === file.name.split('.')[0]) {
            userDocumentData[fileKey]['download_url'] = urls[index];
            userDocumentData[fileKey]['metadata'] = metas[index];
            userDocumentData[fileKey]['file_name'] = file.name;
            userDocumentData[fileKey]['file_ref'] = file;
          }
        }
      });

      return true;
    } catch (error) {
      console.error('Error listing files:', error);
      return false;
    }
  }

  // Function to handle file uploads
  async function handleFileUpload(event, documentKey) {
    const file = event.target.files[0];
    // Handle the file as needed (upload, process, etc.)
    const file_ext = file.name.split('.').pop();

    // Check if the file extension is allowed
    if (!userDocumentData[documentKey]['allowed_extensions'].includes(file_ext)) {
      alert('File type not allowed!');
      return;
    }

    const storageRef = ref(FBStorage, `private/${FBUser.uid}/${documentKey}.${file_ext}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      snapshot => {
        // Handle upload progress if needed
        uploadPercentage[documentKey] = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      },
      error => {
        console.error('Error uploading file:', error);
      },
      () => {
        // File uploaded successfully, update the list
        refreshFiles();
      }
      );
  }

  // Function to delete a file
  async function deleteFile(fileRef) {
    try {
      await deleteObject(fileRef);
      console.log('File deleted successfully');
      refreshFiles();
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  }

  // Utility function to get the file extension from the full path
  function extractFileType(path) {
    var file_type = path.split('.').pop();
    return file_type.toUpperCase();
  }

  function bytesToSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) {
      return '0 Byte';
    }
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
  }

  //Observer FBUser and refresh files when it changes
  const loadFiles = async () => {
    if ($user !== null) {
      haveLoadedFiles = await refreshFiles();
    }
  };
  // Observe changes to the user store
  const unsubscribe = user.subscribe($user => {
    loadFiles();
  });
  onMount(async () => {
    loadFiles();
  });
</script>

<div>
  {#if $user === null}
  <div class="alert alert-danger orange-background poppins" role="alert">You must be logged in to upload documents.</div>
  {/if}
  {#if $user !== null && !haveLoadedFiles}
  <div class="alert alert-info blue-background gray-text poppins" role="alert">Loading files...</div>
  {/if}
  {#if $user !== null && haveLoadedFiles}
  <div class="container-fluid mb-3">
    <div class="row row_padded">
      <div class="col-md-8 offset-md-2">
        {#each Object.keys(fileSections) as section (section)}
        <div class="card p-4 mb-3 blue-background">
          <div class="card-body blue-background">
            <h2 class="card-title mb-3 poppins gray-text">{section}</h2>
            {#each fileSections[section] as fileKey, index (index)}
            <div class="mb-3">
              <label for={`file${index + 1}`} class="poppins gray-text thin-text"
              >{userDocumentData[fileKey]['title']}:</label
              >

              {#if userDocumentData[fileKey]['download_url'] === undefined}
              <div class="input-group">
                <input
                type="file"
                class="form-control"
                id={`file${index + 1}`}
                accept={'.' + userDocumentData[fileKey]['allowed_extensions'].join(', .')}
                on:change={e => handleFileUpload(e, fileKey)}
                />
              </div>
              {/if}

              {#if uploadPercentage[fileKey] !== undefined}
              <div>
                {#if uploadPercentage[fileKey] > 0 && uploadPercentage[fileKey] < 100}
                <div class="progress">
                  <div
                  class="progress-bar progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  style={`width: ${uploadPercentage[fileKey]}%`}
                  aria-valuenow={uploadPercentage[fileKey]}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  >
                  {Math.round(uploadPercentage[fileKey])}%
                </div>
              </div>
              {/if}
            </div>
            {/if}

            {#if userDocumentData[fileKey]['download_url'] !== undefined}
            <span class="gray-text">Uploaded</span>
            <div>
              <a href={userDocumentData[fileKey]['download_url']} target="_blank">
                <button class="document-download-button black-text">Download</button>
              </a>
              
              <button
              on:click={() => deleteFile(userDocumentData[fileKey]['file_ref'])}
              class="document-delete-button gray-text"
              >
              <i class="bi bi-trash" /> Delete
            </button>
          </div>
          {/if}
        </div>
        {/each}
      </div>
    </div>
    {/each}
  </div>
</div>
</div>
{/if}
</div>

<style>
  .file-name {
    color: rgb(21, 21, 21);
    font-weight: bold;
  }

  .document-download-button,.document-delete-button{
    border: 1px solid #EEEDED;
    padding: 5px 15px;
    border-radius: 10px;
    font-size: 1rem;
    transition: scale 0.1s;
  }

  .document-delete-button{
    background-color: #BF080A;
  }

  .document-download-button{
    background-color: EEEDED;
  }

</style>
