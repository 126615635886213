<script>
    import Navbar from './Navbar.svelte';
    import 'bootstrap/dist/css/bootstrap.min.css';
    import 'bootstrap/dist/js/bootstrap.min.js';

    //my current version set
    export let version = "DUMMY_VERSION_NUMBER";
    console.log("version: " + version);

    import { onMount } from 'svelte';
    import { login_anonymously} from './Firebase.js';

    onMount(() => {
      login_anonymously();
    });
</script>

<Navbar />