<script>
  import { onMount } from 'svelte';
  import { resetPassword } from "./Firebase";
  import Footer from "./Footer.svelte";
  
  let email = '';
  let successMessage = '';
  let errorMessage = '';
  let isResetSuccessful = false;
  
  const handleResetPassword = async () => {
    try {
        // Call the resetPassword function
        successMessage = await resetPassword(email);
        errorMessage = ''; // Clear any previous error message
        isResetSuccessful = true; // Set the success state
      } catch (error) {
        if (error.message.includes('invalid-email')) {
          errorMessage = 'Please enter a valid email address.';
        } else {
          errorMessage = 'An unexpected error occurred. Please try again later.';
        }
        successMessage = ''; // Clear any previous success message
        console.error(error);
      }
    };
  </script>
  
  <style>
    .centered-button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .navbar_padding {
      padding-top: 69px; /* Adjust according to the height of your navbar */
    }
    .row_padded {
      margin-top: 2rem;
    }
  </style>
  
  <div class="narrow-container navbar-padding row_padded full-height">
    <div class="card mx-auto">
      <div class="card-body">
        <h2 class="card-title text-center poppins">Forgot your password?</h2>
        <p class="text-center px-4 poppins">
          Enter your email to receive instructions to reset your password.
        </p>
        
        <div class="mb-3">
          <input type="email" id="email" class="form-control poppins" bind:value={email} placeholder="Enter your email" />
        </div>
        
        {#if !isResetSuccessful}
        <div class="centered-button">
          <button on:click={handleResetPassword} class="primary-button poppins ">Reset Password</button>
        </div>
        {/if}
        
        {#if successMessage}
        <div class="alert alert-success mt-3 poppins">{successMessage}</div>
        {/if}
        
        {#if errorMessage}
        <div class="alert alert-danger mt-3 poppins">{errorMessage}</div>
        {/if}
      </div>
    </div>
  </div>

  <Footer/>
  