<script>
  import { user } from './Firebase.js';
  import Footer from "./Footer.svelte";
  import GetStartedButton from "./GetStartedButton.svelte";
  import { fade, slide} from "svelte/transition";

  var show_homeowner = 0;

  function maybe_toggle_view(){
    var ho = document.getElementById('ho_toggle');
    var sfr = document.getElementById('sfr_toggle');
    var mf = document.getElementById('mf_toggle');
    if (show_homeowner == 0){
      ho.classList.remove("secondary-button-less-padding")
      ho.classList.add("primary-button-less-padding")
      sfr.classList.remove("primary-button-less-padding")
      sfr.classList.add("secondary-button-less-padding")
      mf.classList.remove("primary-button-less-padding")
      mf.classList.add("secondary-button-less-padding")
    }else if (show_homeowner == 1){
      ho.classList.remove("primary-button-less-padding")
      ho.classList.add("secondary-button-less-padding")
      sfr.classList.remove("secondary-button-less-padding")
      sfr.classList.add("primary-button-less-padding")
      mf.classList.remove("primary-button-less-padding")
      mf.classList.add("secondary-button-less-padding")
    } else if (show_homeowner == 2){
      ho.classList.remove("primary-button-less-padding")
      ho.classList.add("secondary-button-less-padding")
      sfr.classList.remove("primary-button-less-padding")
      sfr.classList.add("secondary-button-less-padding")
      mf.classList.remove("secondary-button-less-padding")
      mf.classList.add("primary-button-less-padding")

    }
  }
  function switch_view_sfr(){
    if (show_homeowner != 1){
      show_homeowner = 1;
    }
    maybe_toggle_view()
  }
  function switch_view_ho(){
    if (show_homeowner != 0 ){
      show_homeowner = 0;
    }
    maybe_toggle_view()
  }
  function switch_view_mf(){
    if (show_homeowner != 2){
      show_homeowner = 2;
    }
    maybe_toggle_view()
  }
</script>



<div>
  <div class="d-flex mx-auto flex-column navbar-padding relative right-container bottom-space max-width-container">
    <img id="cover-image" src="/images/cover.png" alt="KnewHomes Cover Image" style="z-index: -1">
    <div id="cover-text-container">
      <h1 class="poppins large-text black-text"><span class="bold-text">Real Estate Equity Access,</span> <span class="blue-text bolder-text blue-underline">Reimagined</span></h1>
      <button id="ho_toggle" class="primary-button-less-padding"  on:click={switch_view_ho}>For Homeowners</button>
      <button id="sfr_toggle" class="secondary-button-less-padding"  on:click={switch_view_sfr}>For Single Family Rentals</button>
      <button id="mf_toggle" class="secondary-button-less-padding" on:click={switch_view_mf}>For Multi-Family Rentals</button>
      {#if show_homeowner === 0}
        <div transition:slide={{ delay: 0, duration: 300 }}>
          <h2 class="poppins mt-3 thin-text black-text medium-text relative">Qualify to receive a check for up to 25% of your Home's Value</h2>
          <h2 class="poppins mt-3 thin-text black-text small-text" style="width:50%;">Reinvest your home equity with no monthly payments and no payback for 5 years</h2>
        </div>
      {:else if show_homeowner === 1}
        <div transition:slide={{ delay: 0, duration: 300 }}>
            <h2 class="poppins mt-3 thin-text black-text medium-text relative">Financing for up to 33% of your Property Portfolio's value</h2>
            <h2 class="poppins mt-3 thin-text black-text medium-text relative">No Monthly Payments, No Payback for 10 years</h2>
             <h2 class="poppins mt-3 thin-text black-text small-text header-hook">Receive an additional 15% of your portfolio's value for renovations and property maintainance for free - No Repayment Necessary</h2>
          
        </div>
      {:else if show_homeowner === 2}
        <div transition:slide={{ delay: 0, duration: 300 }}>
          <h2 class="poppins mt-3 thin-text black-text medium-text relative">Qualify to receive a check for up to 45% of your Property's Current or Future Value</h2>
          <h2 class="poppins mt-3 thin-text black-text small-text" style="width:50%;">Multi-Family rental investment with no monthly payments and no payback for 10 years</h2>
        </div>
      {/if}
      <GetStartedButton text="Speak With Us Now" classes="primary-button"/>
    </div>
  </div>


  {#if show_homeowner === 0}
    <div class="featurette centered blue-background ">
      <div class="row max-width-container">
        <h1 class="featurette-heading poppins gray-text bottom-space">We're Here to <b>Help Homeowners</b></h1>
        <div class="col-lg-3">
          <img src="/images/icon-video-call.png" alt="Video Call" class="steps-icon"/>
          <h5 class="fw-bold centered poppins gray-text small-text">Step 1</h5>
          <p class="centered poppins gray-text bottom-space smaller-text">
            We start with a quick 10-minute Zoom call to explain our process. It's easy, and you can ask any questions you may have.
          </p>
        </div>
        <div class="col-lg-3">
          <img src="/images/icon-document.png" alt="Document" class="steps-icon"/>
          <h5 class="fw-bold centered poppins gray-text small-text">Step 2</h5>
          <p class="gray-text centered poppins bottom-space smaller-text">If you're interested, we'll need some documents (we'll show you what's needed).</p>
        </div>
        <div class="col-lg-3">
          <img src="/images/icon-payment.png" alt="Document" class="steps-icon"/>
          <h5 class="fw-bold centered poppins gray-text small-text ">Step 3</h5>
          <p class="gray-text centered poppins bottom-space smaller-text">
            Once you qualify, we'll check your home's value. If everything's good, we give you a check,
            usually 10-25% of your home's worth.
          </p>
        </div>
        <div class="col-lg-3">
          <img src="/images/icon-refund.png" alt="Document" class="steps-icon"/>
          <h5 class="fw-bold centered poppins gray-text small-text">Step 4</h5>
          <p class="gray-text centered poppins bottom-space smaller-text">
            When the agreement ends or if you move before, you pay back the initial amount plus a share of your home's
            growth.
          </p>
        </div>
      </div>
    </div>

    <div class="row featurette max-width-container ">
      <div class="col-md-7 order-md-2 bottom-mobile-padding" style="z-index: 2;">
        
        <h2 class="featurette-heading poppins black-text ">No Monthly Payments, No Tie To Interest Rates</h2>
        <p class="poppins small-text black-text thin-text mt-3">
          KnewHomes Charges a one time 6% Fee on the equity investment. From then on, there are no monthly payments and the payback is tied to the value of your home.
        </p>

        <p class="poppins black-text smaller-text mt-3">
          We provide one of the <b>most affordable</b> home equity investment option in the market.
        </p>
        <p class="poppins black-text smaller-text mt-3">
          We do not adjust the value of your home for investment risk - We give you the <b>true value</b> of your
          home.
        </p>
        <p class="poppins black-text smaller-text mt-3">
          Unlike our competitors, we do not invest directly in your home, we pair you with institutional investors, ensuring there is <b>no conflict of interest</b>.
        </p>
      </div>
      <div class="col-md-5 order-md-1 relative">
      </div>
    </div>

    <div class="row max-width-container">
      <div class="col-md-5 order-md-1 relative" style="z-index: 1;">
        <img class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" 
        src="/images/icon-house.png" alt="Video Call" id="left-pop-image"/>
      </div>
    </div>

    <div class="orange-background">
      <div class="featurette max-width-container poppins small-text black-text">
        <div class="comparison-container vertically-aligned">
          <div class="comparison comparison-text">
            <div class=" medium-text"><b>Knew</b>Homes</div>
          </div>
          <div class="comparison comparison-images">
            <div class=" bold-text">Vs.</div>
          </div>
          <div class="comparison comparison-text">
            <div class=" medium-text">15 Year HELOC</div>
            
          </div>
        </div>
        <div class="comparison-container vertically-aligned">
          
          <div class="comparison comparison-text">
            <div class=" bold-text">Borrowed</div>
            <div class=" thin-text">$100,000</div>
          </div>
          <div class="comparison comparison-images"></div>
          <div class="comparison comparison-text">
            <div class="bold-text">Borrowed</div>
            <div class="thin-text">$100,000</div>
          </div>
        </div>
        <hr/>
        <div class="comparison-container vertically-aligned">
          <div class="comparison comparison-text">
            <div class=" bold-text">Due At Closing</div>
            <div class="thin-text">6% Total Loan Amount</div>
          </div>
          <div class="comparison comparison-images"></div>
          <div class="comparison comparison-text">
            <div class=" bold-text">Due At Closing</div>
            <div class="thin-text">2-5% Total Loan Amount</div>
          </div>
        </div>
        <hr/>
        <div class="comparison-container vertically-aligned">
          <div class="comparison comparison-text">
            <div class=" bold-text">Due Monthly at 5% Interest Rate</div>
            <div class="thin-text">$0</div>
          </div>
          <div class="comparison comparison-images"></div>
          <div class="comparison comparison-text">
            <div class=" bold-text">Due Monthly at 5% Interest Rate</div>
            <div class="thin-text">$790</div>
          </div>
        </div>
        <hr/>
        <div class="comparison-container vertically-aligned">
          <div class="comparison comparison-text">
            <div class=" bold-text">Home Appreciation</div>
            <div class="thin-text">4% Yearly for 5 Years</div>
          </div>
          <div class="comparison comparison-images"></div>
          <div class="comparison comparison-text">
            <div class=" bold-text">Home Appreciation</div>
            <div class="thin-text">4% Yearly for 5 Years</div>
          </div>
        </div>
        <hr/>
        <div class="comparison-container vertically-aligned">
          <div class="comparison comparison-text">
            <div class=" bold-text">Due At Term End</div>
            <div class="thin-text">$21,000 cash or lien</div>
          </div>
          <div class="comparison comparison-images"></div>
          <div class="comparison comparison-text">
            <div class=" bold-text">Due At Term End</div>
            <div class="thin-text">$0</div>
          </div>
        </div>
        <hr/>
        <div class="comparison-container vertically-aligned">
          <div class="comparison comparison-text">
            <div class=" bold-text medium-text">Total Cost</div>
            <div class="">$27,000</div>
          </div>
          <div class="comparison comparison-images"></div>
          <div class="comparison comparison-text">
            <div class=" bold-text medium-text">Total Cost</div>
            <div class="">$42,300-$45,300</div>
          </div>
        </div>
       </div>
    </div>

    <div class="featurette gray-background ">
      <div class="max-width-container">
        <div class="row vertically-aligned ">
          <div class="col-md-7 order-md-2">
            <h2 class="featurette-heading poppins black-text">KnewHomes Invests in You</h2>
            <p class="poppins mt-3 small-text black-text thin-text">
              We believe unlocking your home equity should not be expensive or require a large, long-term and frequent repayment schedule. <b>It's your equity</b>. Access it when you need it.
            </p>
          </div>
          <div class="col-md-5 order-md-1 centered">
            <img class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto animation-image" 
            src="/images/icon-hand-shake.png" alt="Video Call"/>
          </div>
        </div>
      <div class="row vertically-aligned">
        <div class="col-md-7 order-md-2">
          <h2 class="featurette-heading poppins black-text mt-5">Your Home - Your Rules</h2>
          <p class="poppins mt-3 small-text black-text thin-text">
            An Investment in your home equity does not mean that you share your home. It is simply an investment in your home value appreciation. <b>You continue to live your way and by your rules</b>.
          </p>
        </div>
        <div class="col-md-5 order-md-2 centered">
          <img class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto animation-image" 
          src="/images/icon-home-clipart.png" alt="Video Call" />
        </div>
        
      </div>
        <div class="row vertically-aligned">
          <div class="col-md-7 order-md-2">
            <h2 class="featurette-heading poppins black-text mt-5">Financial Independence</h2>
            <p class="poppins mt-3 small-text black-text thin-text">
              You deserve to use the value that <b>you worked hard for</b>. Whether you use it to pay down your mortgage, remodel your kitchen or purchase that car you have been dreaming of - We are here to make it a reality.
            </p>
          </div>
          <div class="col-md-5 order-md-1 centered">
            <img class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto animation-image" 
            src="/images/icon-chains.png" alt="Video Call"/>
          </div>
        </div>
      </div>
    </div>

    <div class="featurette centered blue-background">
      <div class="row max-width-container">
        <h1 class="featurette-heading poppins gray-text bottom-space">Put <b>Your</b> Equity To Work</h1>
        <div class="col-lg-3 bottom-mobile-padding">
          <div class="vertically-aligned horizontally-aligned">
            <img
            class="uses-icon"
            src="/images/icon-chart.png"
            alt="Unsplash"
            aria-label="Placeholder"
            focusable="false"
            />
            
            <h2 class="poppins gray-text">For Investors</h2>
          </div>
          <p class="gray-text">
            Brian secured a $1,000,000 Equity investment for 25% of his home value in Sarasota Florida. He reinvested his
            money into his stock portfolio and made an 8% return by unlocking his home equity.
          </p>
          
        </div>
        <div class="col-lg-3 bottom-mobile-padding">
          <div class="vertically-aligned horizontally-aligned">
            <img
            class="uses-icon"
            src="/images/icon-mortgage.png"
            alt="Unsplash"
            aria-label="Placeholder"
            focusable="false"
            />
            <h2 class=" poppins gray-text">For Mortgages</h2>
          </div>
          <p class="gray-text">
            Sarah secured a 15% Equity investment in her home for $20,000. She used this to pay down her mortgage so that
            her monthly mortgage payments were nearly cut in half.
          </p>
        </div>
        <div class="col-lg-3 bottom-mobile-padding">
          <div class="vertically-aligned horizontally-aligned">
            <img
            class="uses-icon"
            src="/images/icon-portfolio.png"
            alt="Unsplash"
            aria-label="Placeholder"
            focusable="false"
            />
            <h2 class=" poppins gray-text">For Portfolios</h2>
          </div>
          <p class="gray-text">
            Mark could use the $200,000 he received in exchange for 10% of his $2 million Single Family Rental Property to
            buy 2 new homes with 15% down. The 2 properties generate $84,000 per year and are collectively worth $1.33
            million without paying interest or principal for 5-10 years.
          </p>
        </div>
        <div class="col-lg-3">
          <div class="vertically-aligned horizontally-aligned">
            <img
            class="uses-icon"
            src="/images/icon-cash.png"
            alt="Benfits"
            aria-label="Placeholder"
            focusable="false"
            />
            <h2 class="poppins gray-text">For Cash</h2>
          </div>
          <p class="gray-text">Ryan was able to secure $100,000 for 10% of his Condo in Tampa and pay 0$ for 5 years using KnewHomes</p>
        </div>
        <!-- /.col-lg-3 -->
        <div class="row mt-4">
          <div class="col-lg-12">
            <p>
              <a
              class="mt-3 primary-button"
              href="#disclaimerModal"
              role="button"
              data-bs-toggle="modal"
              data-bs-target="#disclaimerModal">Disclaimer</a
              >
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Disclaimer Modal -->
    <div
    class="modal fade"
    id="disclaimerModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="disclaimerModalLabel"
    aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="disclaimerModalLabel">Privacy Policy for Knewhomes.io</h5>
          </div>
          <div class="modal-body poppins">
            <h6>Effective Date: November 8, 2023</h6>

            <h6>Introduction</h6>
            <p>
              Knewhomes.io respects your privacy and is committed to protecting your personal data. This privacy policy
              outlines our practices regarding the collection, use, and sharing of your personal information.
            </p>

            <h6>Information We Collect</h6>
            <p>
              We collect information that can identify you, such as your name, contact details, and usage data. This
              information is gathered through interactions with our website and services.
            </p>

            <h6>Use of Your Information</h6>
            <p>
              Your information is used to provide and improve our services, communicate with you, and ensure the security
              of our platform.
            </p>

            <h6>Sharing of Information</h6>
            <p>
              We may share your information with service providers or as required by law. We do not sell personal
              information to third parties.
            </p>

            <h6>Your Rights</h6>
            <p>
              You have the right to access, correct, or delete your personal data, and to opt out of data sales or
              disclosures.
            </p>

            <h6>Children's Privacy</h6>
            <p>
              Our website is not intended for individuals under 16 years of age. We do not knowingly collect information
              from children without parental consent.
            </p>

            <h6>International Data Transfer</h6>
            <p>
              Your information may be processed in the United States and other jurisdictions, in accordance with
              applicable laws.
            </p>

            <h6>Changes to This Policy</h6>
            <p>
              We reserve the right to make changes to this policy. Material changes will be communicated through our
              website.
            </p>

            <h6>Contact Us</h6>
            <p>
              For questions or concerns about our privacy practices, please contact us at <a
              href="mailto:info@knewhomes.io">info@knewhomes.io</a
              >.
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="secondary-button" data-bs-dismiss="modal">Okay</button>
          </div>
        </div>
      </div>
    </div>

    <div class="relative max-width-container">
      <img class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" 
      src="/images/icon-dollar-signs.png" alt="Video Call" id="right-pop-image"/>
    </div>

    <div class="row featurette max-width-container">
      <div class="col-md-7 order-md-1" >
        <h2 class="featurette-heading poppins black-text top-mobile-padding">General Qualifications</h2>
        <div class="checkmarked-text mt-3">
          <img src="/images/icon-checkmark.png" alt="" class="checkmark-icon inline"/>
          <div class="poppins black-text inline smaller-text">
            Own More Than 40% Equity in Your Home
          </div>
          
        </div>
        <div class="checkmarked-text mt-3">
          <img src="/images/icon-checkmark.png" alt="" class="checkmark-icon inline"/>
          <div class="poppins black-text inline smaller-text">
            Debt To Income Ratio of Less Than 40%
          </div>
        </div>
        <div class="checkmarked-text mt-3">
          <img src="/images/icon-checkmark.png" alt="" class="checkmark-icon inline"/>
          <div class="poppins black-text inline smaller-text">
            Have No Additional Liens on Your Property
          </div>
        </div>
        <p class="poppins black-text mt-3">
          Similar to a mortgage Pre-Approval, you must submit background information such as credit score, income, and mortgage payment history.
        </p>
        <p>
          <a
          class="mt-3 secondary-button"
          href="#documentationModal"
          role="button"
          data-bs-toggle="modal"
          data-bs-target="#documentationModal">Required Documentation</a
          >
          <GetStartedButton/>
        </p>
      </div>
      <div class="col-md-5 order-md-2">
      </div>
    </div>

    <div class=" featurette gray-background ">
      <div class="max-width-container">
        <h1 class="featurette-heading centered poppins black-text bottom-space">Frequently Asked Questions</h1>
        <div class="narrowest-container-centered">
          <details class="gray-text poppins smaller-text">
            <summary>What if I want to sell my Home?</summary>
            <p class="indented poppins smallest-text">A sale option will be in the agreement. If the Homeowner chooses to sell earlier - it can be settled with the investor or the homeowner will allow an equity adjustment for the investor.</p>
          </details>
          <details class="gray-text poppins smaller-text">
            <summary>What if I want the equity in my home back?</summary>
            <p class="indented poppins smallest-text">A homeowner can purchase back the equity that they have offered for investment at a specified time in the agreement. The repurchase of the home equity will be at the current market price.</p>
          </details>
          <details class="gray-text poppins smaller-text">
            <summary>What if the property incurs damages?</summary>
            <p class="indented poppins smallest-text">The cost of damage to the property is on the homeowner and insurance that the homeowner has. If the house is destroyed, the investor will receive the fair market value of their shares in the home.</p>
          </details>
        </div>
      </div>
    </div>

    <div
    class="modal fade"
    id="documentationModal"
    tabindex="-1"
    aria-labelledby="documentationModalLabel"
    aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="documentationModalLabel">Necessary Documentation</h5>
          </div>
          <div class="modal-body poppins">
            <h6>Applicants:</h6>
            <ul>
              <li>Name, date of birth, and Social Security number.</li>
              <li>Address (and previous address, depending on how long you’ve been in your current home).</li>
              <li>Employer’s name and address</li>
              <li>
                A valid copy of a government-issued photo ID, such as a driver’s license or state identification card.
              </li>
            </ul>

            <h6>Income and asset documentation:</h6>
            <ul>
              <li>Pay stubs for the previous 30 days</li>
              <li>W-2 forms for the previous two years to verify earnings.</li>
              <li>Current-year profit-and-loss statement (if they’re self-employed).</li>
              <li>Tax returns for the previous two years (if they’re self-employed).</li>
              <li>
                Disability, Social Security, or pension income award letters. (May also substitute bank statements or
                1099s to show proof of these types of income.)
              </li>
              <li>
                Documentation of retirement account distributions, including award letters, bank statements, or tax forms
              </li>
              <li>
                Alimony and child support documentation, which can include a divorce decree, divorce settlement document,
                court order, or bank statements showing deposits made to their account (Partial documentation is OK)
              </li>
            </ul>

            <h6>Asset documentation can include:</h6>
            <ul>
              <li>Bank account statements for the previous two months.</li>
              <li>
                Investment and retirement account statements for the previous two months. This may exclude assets held on
                another’s behalf (ie. custodial accounts)
              </li>
            </ul>

            <h6>Property documentation:</h6>
            <ul>
              <li>
                A copy of your homeowners insurance declarations page, which you may be able to receive from your insurer.
              </li>
              <li>A copy of your most recent property tax bill.</li>
              <li>
                Your most recent mortgage statement showing the balance due and escrowed amounts for homeowners insurance,
                property taxes, private mortgage insurance, or homeowners association fees.
              </li>
              <li>A copy of your flood insurance declaration if your property is in a flood zone.</li>
            </ul>
          </div>
          <div class="modal-footer">
            <button type="button" class="secondary-button" data-bs-dismiss="modal">Okay</button>
          </div>
        </div>
      </div>
    </div>
  {:else if show_homeowner === 1}
    <div class="featurette centered gray-background ">
      <div class="row max-width-container">
        <h1 class="featurette-heading poppins black-text bottom-space">Access your Property Portfolio Equity <b>Without Selling</b></h1>
        <div class="row">
          <div class="col-lg">
            <img src="/images/icon-video-call-black.png" alt="Video Call" class="steps-icon"/>
            <h5 class="fw-bold centered poppins black-text small-text">Step 1</h5>
            <p class="centered poppins black-text bottom-space smaller-text">
              We start with a quick 10-minute Zoom call to explain our process. It's easy, and you can ask any questions you may have.
            </p>
          </div>
          <div class="col-lg">
            <img src="/images/icon-document-black.png" alt="Video Call" class="steps-icon"/>
            <h5 class="fw-bold centered poppins black-text small-text">Step 2</h5>
            <p class="black-text centered poppins bottom-space smaller-text">If you're interested, we'll need some documents (we'll show you what's needed).</p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg">
            <img src="/images/icon-payment-black.png" alt="Video Call" class="steps-icon"/>
            <h5 class="fw-bold centered poppins black-text small-text ">Step 3</h5>
            <p class="black-text centered poppins bottom-space smaller-text">
              Once you qualify, we'll check the value of the portion of your portfolio you are financing against. If everything's good, we give you a check worth 33% of the value + an additional check for 15% of the value for property improvements that will not need to be paid back.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg">
            <img src="/images/icon-closing-black.png" alt="Video Call" class="steps-icon"/>
            <h5 class="fw-bold centered poppins black-text small-text ">Step 4</h5>
            <p class="black-text centered poppins bottom-space smaller-text">
              You pay a one time 6% origination fee + 33% of the rental income during the term of the agreement. There are no payments towards the principal during this time and no interest payments ever. 
            </p>
          </div>
          <div class="col-lg">
            <img src="/images/icon-refund-black.png" alt="Video Call" class="steps-icon"/>
            <h5 class="fw-bold centered poppins black-text small-text">Step 5</h5>
            <p class="black-text centered poppins bottom-space smaller-text">
              When the agreement ends you pay back 33% of the current value of the portfolio. You do not pay back the 15% for property improvements and all future gains made from the improvements are yours to keep. 
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="blue-background">
      <div class="row featurette max-width-container">
        <div class="col-md-7 order-md-2 bottom-mobile-padding" style="z-index: 2;" >
          
          <h2 class="featurette-heading poppins gray-text ">Break Ground Faster, Without Breaking The Bank</h2>
          <p class="poppins small-text gray-text thin-text mt-3">
            Secure your property investment today knowing you won't have to repay until project <b>completion</b>.
          </p>
          <p class="poppins gray-text smaller-text mt-3">After that, just sit back and enjoying the long term investment gains for years to come.
          </p>
          <p class="poppins gray-text smaller-text mt-3">
            We provide one of the <b>most affordable</b> single family rental investment option in the market.
          </p>
        </div>
        <div class="col-md-5 order-md-1 relative">
        </div>
      </div>

      <div class="row max-width-container" >
        <div class="col-md-5 order-md-1 relative" style="z-index: 1;">
          <img class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" 
          src="/images/icon-house_bw.png" alt="Video Call" id="left-pop-image"/>
        </div>
      </div>
    </div>

    <div class="gray-background">
      <div class="featurette row max-width-container poppins black-text">
          <h1 class="featurette-heading bottom-space centered">Let's Talk <b>Numbers</b></h1>

          <div class="bottom-space">
            <div class="row vertically-aligned centered-mobile">
              <div class="col-md-7 order-md-1">
                <h4>Meet John. John owns 30 properties each worth 400k. He wants to finance 25% of his portfolio using <b>Knew</b>Home's Home Equity Investment (HEI)</h4>
              </div>
              <div class="col-md-5 order-md-2 centered">
                <img class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto animation-image-small" 
                src="/images/icon-man.png" alt="Video Call"/>
              </div>
            </div>
          </div>

          <div class="bottom-space">
            <div class="row vertically-aligned centered-mobile">
              <div class="col-md-7 order-md-2">
                <h4>That is <b>$3 million</b> in equity financing + an additional <b>$1.5 million</b> for capital improvements, <i>for free</i>
                </h4>
              </div>
              <div class="col-md-5 order-md-1 centered">
                <img class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto animation-image-small" 
                src="/images/icon-money-in.png" alt="Video Call"/>
              </div>
            </div>
          </div>
          
          <table class="bottom-space narrow-table">
            <tr class="table-heading">
              <th colspan=3>
                Effects of $1.5 Million Towards Capital Improvements
              </th>
            </tr>
            <tr>
              <td></td>
              <th>Valuation</th>
              <th>Annual Rent</th>
            </tr>
            <tr>
              <th>Before KnewHomes Equity Investment</th>
              <td>$12 million</td>
              <td>$1.08 million</td>
            </tr>
            <tr>
              <th>After KnewHomes Equity Investment</th>
              <td>$15 million</td>
              <td>$1.18 million</td>
            </tr>
          </table>

          <div class="bottom-space">
            <div class="row vertically-aligned centered-mobile">
              <div class="col-md-7 order-md-2">
                <h4 class="centered">John sees an estimated <b>25% increase in portfolio valuation</b> and <b>10% incease in rental proceeds</b> by putting his capital improvement money to work</h4>
              </div>
              <div class="col-md-5 order-md-1 centered">
                <img class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto animation-image-small" 
                src="/images/icon-chart-black.png" alt="Video Call"/>
              </div>
            </div>
          </div>

          <div class="bottom-space">
            <div class="row vertically-aligned centered-mobile">
              <div class="col-md-7 order-md-1">
                <h4>John now waits 10 years for the loan to mature. During this time rents grow at <b>8% per year</b> and property values grow at <b>5% per year</b>
                </h4>
              </div>
              <div class="col-md-5 order-md-2 centered">
                <img class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto animation-image-small" 
                src="/images/icon-time.png" alt="Video Call"/>
              </div>
            </div>
          </div>

          <table  class="bottom-space wide-table">
            <tr class="table-heading">
              <th colspan=3 align=center>
                Property Portfolio Data at Term End
              </th>
            </tr>
            <tr>
              <th></th>
              <th>Traditional Loan at 8.5% APR</th>
              <th>KnewHomes Equity Investment</th>
            </tr>
            <tr>
              <th>Valuation</th>
              <td>$19.54 million</td>
              <td>$24.43 million</td>
            </tr>
            <tr>
              <th>Annual Rent</th>
              <td>$1.8 million</td>
              <td>$2.42 million</td>
            </tr>
            <tr>
              <th>Additional Rental Revenue at Term End</th>
              <td>$0</td>
              <td>$1.5 million</td>
            </tr>
            <tr>
              <th>Additional Property Value at Term End</th>
              <td>$0</td>
              <td>$4.89 million</td>
            </tr>
          </table>

          <div class="bottom-space">
            <div class="row vertically-aligned centered-mobile">
              <div class="col-md-7 order-md-2">
                <h4>John's <b>effective</b> loan amount is <b>$4.5 million</b> with the increase in rental incomes</h4>
              </div>
              <div class="col-md-5 order-md-1 centered">
                <img class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto animation-image-small" 
                src="/images/icon-dollar-sign.png" alt="Video Call"/>
              </div>
            </div>
          </div>


          <table class="bottom-space narrow-table">
            <tr class="table-heading">
              <th colspan=2>
                Repayment on $4.5 Million in Financing
              </th>
            </tr>
            <tr>
              <th>Traditional Loan at 8.5% APR</th>
              <th>KnewHomes HEI at 25%</th>
            </tr>
            <tr>
              <td>$6.7 million</td>
              <td>$6.1 million</td>
            </tr>
          </table>

          <div class="bottom-space">
            <div class="row vertically-aligned centered-mobile">
              <div class="col-md-7 order-md-1 centered-mobile">
                <h3 class="bottom-space">With the property value added, John's <b>net cost</b> of the loan is <b>$3.65 million</b> leading to an effective savings of <b>45%</b></h3>
                <h3>He also gets to <b>keep all the benefits</b> of the increased valuation and rent revenue.</h3>
              </div>

              <div class="col-md-5 order-md-2 centered">
                <img class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto animation-image-small" 
                src="/images/icon-happy-person.png" alt="Video Call"/>
              </div>
            </div>
          </div>

          <p class="smallest-text centered">Disclaimer: Does Not include NPV savings with no payments for 10 years. These are estimated appreciation values and improvements. KnewHomes Corp. These numbers are in no way a guarantee of outcome</p>


        </div>
    </div>
  {:else if show_homeowner === 2}
    <div class="featurette centered orange-background ">
      <div class="row max-width-container">
        <h1 class="featurette-heading poppins black-text bottom-space">We're Here to <b>Help Multi-Family Rental Owners</b></h1>
        <div class="col-lg-3">
          <img src="/images/icon-video-call-black.png" alt="Video Call" class="steps-icon"/>
          <h5 class="fw-bold centered poppins black-text small-text">Step 1</h5>
          <p class="centered poppins black-text bottom-space smaller-text">
            We start with a quick 10-minute Zoom call to explain our process. It's easy, and you can ask any questions you may have.
          </p>
        </div>
        <div class="col-lg-3">
          <img src="/images/icon-document-black.png" alt="Document" class="steps-icon"/>
          <h5 class="fw-bold centered poppins black-text small-text">Step 2</h5>
          <p class="black-text centered poppins bottom-space smaller-text">If you're interested, we'll need some documents and some information about your development.</p>
        </div>
        <div class="col-lg-3">
          <img src="/images/icon-payment-black.png" alt="Document" class="steps-icon"/>
          <h5 class="fw-bold centered poppins black-text small-text ">Step 3</h5>
          <p class="black-text centered poppins bottom-space smaller-text">
            Once you qualify we'll estimate your development's current or future value. If everything's good, we give you a check,
            usually 33-45% of your property's worth.
          </p>
        </div>
        <div class="col-lg-3">
          <img src="/images/icon-refund-black.png" alt="Document" class="steps-icon"/>
          <h5 class="fw-bold centered poppins black-text small-text">Step 4</h5>
          <p class="black-text centered poppins bottom-space smaller-text">
            When the agreement ends or if you sell before, you pay back the initial amount plus a share of your property's
            growth.
          </p>
        </div>
      </div>
    </div>

    <div class="blue-background">
    <div class="row featurette max-width-container ">
      <div class="col-md-7 order-md-2 bottom-mobile-padding" style="z-index: 2;">
        
        <h2 class="featurette-heading poppins gray-text ">No Monthly Payments, No Tie To Interest Rates</h2>
        <p class="poppins small-text gray-text thin-text mt-3">
          KnewHomes Charges a one time 6% Fee on the equity investment. From then on, there are no monthly payments and the payback is tied to the value of your property.
        </p>

        <p class="poppins gray-text smaller-text mt-3">
          We provide one of the <b>most affordable</b> multi-family investment option in the market.
        </p>
        <p class="poppins gray-text smaller-text mt-3">
          We do not adjust the value of your property for investment risk - We give you the <b>true value</b> of your
          property.
        </p>
        <p class="poppins gray-text smaller-text mt-3">
          Unlike our competitors, we do not invest directly in your property. We pair you with institutional investors, ensuring there is <b>no conflict of interest</b>.
        </p>
      </div>
      <div class="col-md-5 order-md-1 relative">
      </div>
    </div>
  </div>

    <div class="row max-width-container">
      <div class="col-md-5 order-md-1 relative" style="z-index: 1;">
        <img class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" 
        src="/images/icon-house_bw.png" alt="Video Call" id="left-pop-image"/>
      </div>
    </div>

    <div class="grey-background">
      <div class="featurette max-width-container poppins small-text black-text">
        <div class="comparison-container vertically-aligned">
          <div class="comparison comparison-text">
            <div class=" medium-text"><b>Knew</b>Homes</div>
          </div>
          <div class="comparison comparison-images">
            <div class=" bold-text">Vs.</div>
          </div>
          <div class="comparison comparison-text">
            <div class=" medium-text">15 Year HELOC</div>
            
          </div>
        </div>
        <div class="comparison-container vertically-aligned">
          
          <div class="comparison comparison-text">
            <div class=" bold-text">Borrowed</div>
            <div class=" thin-text">$1,000,000</div>
          </div>
          <div class="comparison comparison-images"></div>
          <div class="comparison comparison-text">
            <div class="bold-text">Borrowed</div>
            <div class="thin-text">$1,000,000</div>
          </div>
        </div>
        <hr/>
        <div class="comparison-container vertically-aligned">
          <div class="comparison comparison-text">
            <div class=" bold-text">Due At Closing</div>
            <div class="thin-text">6% Total Loan Amount</div>
          </div>
          <div class="comparison comparison-images"></div>
          <div class="comparison comparison-text">
            <div class=" bold-text">Due At Closing</div>
            <div class="thin-text">2-5% Total Loan Amount</div>
          </div>
        </div>
        <hr/>
        <div class="comparison-container vertically-aligned">
          <div class="comparison comparison-text">
            <div class=" bold-text">Due Monthly at 5% Interest Rate</div>
            <div class="thin-text">$0</div>
          </div>
          <div class="comparison comparison-images"></div>
          <div class="comparison comparison-text">
            <div class=" bold-text">Due Monthly at 5% Interest Rate</div>
            <div class="thin-text">$7900</div>
          </div>
        </div>
        <hr/>
        <div class="comparison-container vertically-aligned">
          <div class="comparison comparison-text">
            <div class=" bold-text">Home Appreciation</div>
            <div class="thin-text">4% Yearly for 5 Years</div>
          </div>
          <div class="comparison comparison-images"></div>
          <div class="comparison comparison-text">
            <div class=" bold-text">Home Appreciation</div>
            <div class="thin-text">4% Yearly for 5 Years</div>
          </div>
        </div>
        <hr/>
        <div class="comparison-container vertically-aligned">
          <div class="comparison comparison-text">
            <div class=" bold-text">Due At Term End</div>
            <div class="thin-text">$210,000 cash or lien</div>
          </div>
          <div class="comparison comparison-images"></div>
          <div class="comparison comparison-text">
            <div class=" bold-text">Due At Term End</div>
            <div class="thin-text">$0</div>
          </div>
        </div>
        <hr/>
        <div class="comparison-container vertically-aligned">
          <div class="comparison comparison-text">
            <div class=" bold-text medium-text">Total Cost</div>
            <div class="">$270,000</div>
          </div>
          <div class="comparison comparison-images"></div>
          <div class="comparison comparison-text">
            <div class=" bold-text medium-text">Total Cost</div>
            <div class="">$420,300-$450,300</div>
          </div>
        </div>
       </div>
    </div>

    <div class="featurette gray-background ">
      <div class="max-width-container">
        <div class="row vertically-aligned ">
          <div class="col-md-7 order-md-2">
            <h2 class="featurette-heading poppins black-text">KnewHomes Invests in You</h2>
            <p class="poppins mt-3 small-text black-text thin-text">
              We believe accessing your multi-family property equity should not be expensive or require a large, long-term and frequent repayment schedule. <b>It's your equity</b>. Access it when you need it. Looking to build using your future equity? We've got you covered there too. 
            </p>
          </div>
          <div class="col-md-5 order-md-1 centered">
            <img class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto animation-image" 
            src="/images/icon-hand-shake.png" alt="Video Call"/>
          </div>
        </div>
      <div class="row vertically-aligned">
        <div class="col-md-7 order-md-2">
          <h2 class="featurette-heading poppins black-text mt-5">Your Home - Your Rules</h2>
          <p class="poppins mt-3 small-text black-text thin-text">
            An Investment in your property equity does not mean that you share the property. It is simply an investment in your property value appreciation. <b>You continue to own and manage your way and by your rules</b>.
          </p>
        </div>
        <div class="col-md-5 order-md-2 centered">
          <img class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto animation-image" 
          src="/images/icon-home-clipart.png" alt="Video Call" />
        </div>
        
      </div>
        <div class="row vertically-aligned">
          <div class="col-md-7 order-md-2">
            <h2 class="featurette-heading poppins black-text mt-5">Financial Independence</h2>
            <p class="poppins mt-3 small-text black-text thin-text">
              You deserve to use the value that <b>you worked hard for</b>. Whether you use it to pay down your property debt, for a new build, to renovate, or to purchase that new HVAC for the building to attract new tenants - We are here to make it a reality.
            </p>
          </div>
          <div class="col-md-5 order-md-1 centered">
            <img class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto animation-image" 
            src="/images/icon-chains.png" alt="Video Call"/>
          </div>
        </div>
      </div>
      <div class="row mt-4 centered">
          <div class="col-lg-12">
            <p>
              <a
              class="mt-3 primary-button"
              href="#disclaimerModal"
              role="button"
              data-bs-toggle="modal"
              data-bs-target="#disclaimerModal">Disclaimer</a
              >
            </p>
          </div>
        </div>
    </div>

    <!-- Disclaimer Modal -->
    <div
    class="modal fade"
    id="disclaimerModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="disclaimerModalLabel"
    aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="disclaimerModalLabel">Privacy Policy for Knewhomes.io</h5>
          </div>
          <div class="modal-body poppins">
            <h6>Effective Date: November 8, 2023</h6>

            <h6>Introduction</h6>
            <p>
              Knewhomes.io respects your privacy and is committed to protecting your personal data. This privacy policy
              outlines our practices regarding the collection, use, and sharing of your personal information.
            </p>

            <h6>Information We Collect</h6>
            <p>
              We collect information that can identify you, such as your name, contact details, and usage data. This
              information is gathered through interactions with our website and services.
            </p>

            <h6>Use of Your Information</h6>
            <p>
              Your information is used to provide and improve our services, communicate with you, and ensure the security
              of our platform.
            </p>

            <h6>Sharing of Information</h6>
            <p>
              We may share your information with service providers or as required by law. We do not sell personal
              information to third parties.
            </p>

            <h6>Your Rights</h6>
            <p>
              You have the right to access, correct, or delete your personal data, and to opt out of data sales or
              disclosures.
            </p>

            <h6>Children's Privacy</h6>
            <p>
              Our website is not intended for individuals under 16 years of age. We do not knowingly collect information
              from children without parental consent.
            </p>

            <h6>International Data Transfer</h6>
            <p>
              Your information may be processed in the United States and other jurisdictions, in accordance with
              applicable laws.
            </p>

            <h6>Changes to This Policy</h6>
            <p>
              We reserve the right to make changes to this policy. Material changes will be communicated through our
              website.
            </p>

            <h6>Contact Us</h6>
            <p>
              For questions or concerns about our privacy practices, please contact us at <a
              href="mailto:info@knewhomes.io">info@knewhomes.io</a
              >.
            </p>
          </div>
          <div class="modal-footer">
            <button type="button" class="secondary-button" data-bs-dismiss="modal">Okay</button>
          </div>
        </div>
      </div>
    </div>

    <div class="relative max-width-container">
      <img class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" 
      src="/images/icon-dollar-signs.png" alt="Video Call" id="right-pop-image"/>
    </div>

    <div class="row featurette max-width-container">
      <div class="col-md-7 order-md-1" >
        <h2 class="featurette-heading poppins black-text top-mobile-padding">General Qualifications</h2>
        <div class="checkmarked-text mt-3">
          <img src="/images/icon-checkmark.png" alt="" class="checkmark-icon inline"/>
          <div class="poppins black-text inline smaller-text">
            Own The Land You Plan To Develop or Where a Development Already Exists
          </div>
          
        </div>
        <div class="checkmarked-text mt-3">
          <img src="/images/icon-checkmark.png" alt="" class="checkmark-icon inline"/>
          <div class="poppins black-text inline smaller-text">
            Future Construction is Fully Bonded
          </div>
        </div>
        <div class="checkmarked-text mt-3">
          <img src="/images/icon-checkmark.png" alt="" class="checkmark-icon inline"/>
          <div class="poppins black-text inline smaller-text">
            $500mm in Tangible Corporate Assets That Can Be Used For Collateral
          </div>
        </div>
        <div class="checkmarked-text mt-3">
          <img src="/images/icon-checkmark.png" alt="" class="checkmark-icon inline"/>
          <div class="poppins black-text inline smaller-text">
            A Personal Guarantee Of Principal
          </div>
        </div>
        <p>
          <GetStartedButton/>
        </p>
      </div>
      <div class="col-md-5 order-md-2">
      </div>
    </div>


  {/if}
  

<!-- /END OF MAIN CONTENT -->
</div>

<Footer/>

<style>
  #cover-image{
    position: absolute;
    width: 50%;
    right: 0;
    height: auto;
    max-width: 700px;
  }
  #cover-text-container{
    width: 55%;
    height: auto;
  }

  main {
    text-align: left;
    padding: 1% 5% 1% 5%;
  }


  .thin-text{
    font-weight: 300;
  }

  


  /* MARKETING CONTENT
  -------------------------------------------------- */

  /* Center align the text within the three columns below the carousel */

  .section-header {
    margin-bottom: 2rem;
  }
  .marketing {
    margin-bottom: 4rem;
  }
  .marketing h2 {
    font-weight: 400;
  }
  .marketing .col-lg-4 p {
    margin-right: 0.75rem;
    margin-left: 0.75rem;
  }

  /* Featurettes
  ------------------------- */

  .featurette-divider {
    margin: 5rem 0; /* Space out the Bootstrap <hr> more */
  }


  

  .steps-icon{
    width: 35%;
    max-width: 100px;
    display: block;
    margin: 0 auto;
    margin-bottom: 2rem;
  }

  .uses-icon{
    width: 10%;
    display: inline-block;
    margin-bottom: 10px;
    margin-right: 10px;
  }
  .comparison-container{
    padding: 1rem;
  }
  
  .comparison-images{
    width: 10%;
  }
  .comparison-text{
    width: 44%;
  }
  
  .comparison {
    /*width: 49%;*/
    text-align: center;
    display: inline-block;
  }

  .horizontally-aligned {
    justify-content: center;
  }

  .row{
    width: 80%;
    margin:0 auto;
  }

  .inline{
    display: inline-block;
  }

  .checkmark-icon{
    margin-right: 0.5rem;
    width: 30px;
  }

  .animation-image{
    width: 50%;
  }

  .animation-image-small{
    width: 40%;
  }

  .checkmarked-text{
    text-align: left;
  }

  
  #left-pop-image{
    position: absolute;
    left: -50px;
    bottom: 0;
    /*z-index: -1;*/
  }

  #right-pop-image{
    position: absolute;
    right: 10px;
    top: 0;
    max-width: 35%;
    z-index: -1;
  }

  .header-hook{
    width:75%;
  }

  #ho_toggle {
      width: 32%;
    }
    #sfr_toggle {
      width: 32%;
    }
    #mf_toggle {
      width: 32%;
    }


  
  @media screen and (max-width: 600px) {
    .header-hook{
      width:50%;
    }
    .row{
      width: 90%;
      margin:0 auto;
    }

    .centered-mobile {
      text-align: center;
    }
    .animation-image-small{
      padding-top: 20px;
      width: 40%;
    }
    #cover-text-container{
      width: 100%;
      height: auto;
    }
    #cover-image{
      position: absolute;
      width: 50%;
      right: 0;
      bottom: 0;
      height: auto;
      max-width: 900px;
    }
    #left-pop-image{
      right: 50px;
      bottom: 0;
      max-width: 40
    }

    .checkmark-icon{
      margin-right: 0.1rem;
      width: 15px;
    }

    #left-pop-image{
      left: 55%;
      max-width: 49%;
    }

    #ho_toggle {
      width: 95%;
      display: block;
    }
    #sfr_toggle {
      width: 95%;
      display: block;
    }
    #mf_toggle {
      width: 95%;
      display: block;
    }
  } 
</style>
