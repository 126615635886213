<script>
  import DocumentUpload from './DocumentUpload.svelte';
  import Footer from "./Footer.svelte";
  let currentYear = new Date().getFullYear();

  var userDocumentData = {
    front_government_id: {
      title: 'Front of Government issued ID',
      description:
      'A valid copy of a government-issued photo ID, such as a driver’s license or state identification card.',
      file: null, //updated when user uploads file, or if they already have a file
      allowed_extensions: ['png', 'jpeg', 'PNG', 'JPEG', 'jpg', 'JPG', 'pdf'],
    },
    back_government_id: {
      title: 'Back of Government issued ID',
      description:
      'A valid copy of a government-issued photo ID, such as a driver’s license or state identification card.',
      file: null,
      allowed_extensions: ['png', 'jpeg', 'PNG', 'JPEG', 'jpg', 'JPG', 'pdf'],
    },
    most_recent_pay_stub: {
      title: 'Most recent Pay Stub',
      description: 'Pay stubs for the previous 30 days',
      file: null,
      allowed_extensions: ['pdf'],
    },
    previous_pay_stub: {
      title: 'Previous Pay Stub',
      description: 'Pay stubs for the previous 60 days',
      file: null,
      allowed_extensions: ['pdf'],
    },
    w2_form_1: {
      title: `W2 form from ${currentYear - 1}`,
      description: 'W-2 forms for the previous two years to verify earnings.',
      file: null,
      allowed_extensions: ['pdf'],
    },
    w2_form_2: {
      title: `W2 form from ${currentYear - 2}`,
      description: 'W-2 forms for the previous two years to verify earnings.',
      file: null,
      required: true,
      allowed_extensions: ['pdf'],
    },
    tax_return_1: {
      title: `Tax return from ${currentYear - 1}`,
      description: 'Tax returns for the previous two years (if they’re self-employed).',
      file: null,
      allowed_extensions: ['pdf'],
    },
    tax_return_2: {
      title: `Tax return from ${currentYear - 2}`,
      description: 'Tax returns for the previous two years (if they’re self-employed).',
      file: null,
      allowed_extensions: ['pdf'],
    },
    disability_income: {
      title: 'Disability, Social Security, or pension income Award Letters',
      description:
      'Disability, Social Security, or pension income award letters. (May also substitute bank statements or 1099s to show proof of these types of income.)',
      file: null,
      allowed_extensions: ['pdf'],
    },
    retirement_account_distributions: {
      title: 'Documentation of Retirement Account Distributions',
      description:
      'Documentation of retirement account distributions, including award letters, bank statements, or tax forms',
      file: null,
      allowed_extensions: ['pdf'],
    },
    most_recent_bank_statement: {
      title: 'Most Recent Bank/Retirement Account Statement',
      description: 'Bank account statements for the previous two months.',
      file: null,
      allowed_extensions: ['pdf'],
    },
    previous_bank_statement: {
      title: 'Previous Bank/Retirement Account Statement',
      description: 'Bank account statements for the previous two months.',
      file: null,
      allowed_extensions: ['pdf'],
    },
    insurance_declarations_page: {
      title: 'Insurance Declarations Page',
      description:
      'A copy of your homeowners insurance declarations page, which you may be able to receive from your insurer.',
      file: null,
      allowed_extensions: ['pdf'],
    },
    most_recent_property_tax_bill: {
      title: 'Most Recent Property Tax Bill',
      description: 'A copy of your most recent property tax bill.',
      file: null,
      allowed_extensions: ['pdf'],
    },
    most_recent_mortgage_statement: {
      title: 'Most Recent Mortgage Statement',
      description:
      'Your most recent mortgage statement showing the balance due and escrowed amounts for homeowners insurance, property taxes, private mortgage insurance, or homeowners association fees.',
      file: null,
      allowed_extensions: ['pdf'],
    },
  };

  let fileSections = {
    'Applicant Documentation': ['front_government_id', 'back_government_id'],
    'Income and Asset Documentation': [
    'most_recent_pay_stub',
    'previous_pay_stub',
    'w2_form_1',
    'w2_form_2',
    'tax_return_1',
    'tax_return_2',
    'disability_income',
    'retirement_account_distributions',
    'most_recent_bank_statement',
    'previous_bank_statement',
    ],
    'Property Documentation': [
    'insurance_declarations_page',
    'most_recent_property_tax_bill',
    'most_recent_mortgage_statement',
    ],
  };
</script>

<div class="navbar_padding row_padded poppins max-width-container">
  <DocumentUpload {fileSections} {userDocumentData} />
  
  
</div>
<Footer/>

<style>
  .navbar_padding {
    padding-top: 69px; /* Adjust according to the height of your navbar */
  }
  .row_padded {
    margin-top: 2rem;
  }
</style>
