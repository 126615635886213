<script>
  import { Router, Route, Link } from 'svelte-routing';
  import Home from './Home.svelte';
  import Signup from './CreateAccount.svelte';
  import Login from './Login.svelte';
  import About from './About.svelte';
  import { onMount } from 'svelte';
  import { user, logout } from './Firebase';
  import UserProfile from './UserProfile.svelte';
  import PasswordRecovery from './PasswordRecovery.svelte';
  import GetStartedButton from "./GetStartedButton.svelte";
  import tertiary_button from "./GetStartedButton.svelte";

  onMount(() => {
    window.addEventListener('scroll', handleScroll);
    // Clean up the event listener when the component is destroyed
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  let threshold = 100;
  let click_state = false;

  function toggle_colors_for_elements(elements, condition){

    if (elements.length > 0 && condition ){
      for (var i = elements.length - 1; i >= 0; i--) {
        let x = elements[i].classList;
        if (x.contains("black-text")){
          x.remove("black-text");
          x.add("gray-text")
        }
        if (x.contains("black-border")){
          x.remove("black-border");
          x.add("gray-border")
        }
      }
    } else if (elements.length > 0 && !condition){
      for (var i = elements.length - 1; i >= 0; i--) {
        let x = elements[i].classList;
        if (x.contains("gray-text")){

          x.remove("gray-text");
          x.add("black-text")
        }
        if (x.contains("gray-border")){
          x.remove("gray-border");
          x.add("black-border")
        }
      }
    }
  }

  function handleScroll() {
    let element = document.getElementById('navigation-bar');
    if (window.scrollY > threshold && element.classList.contains("transparent")||
      window.scrollY < threshold && !element.classList.contains("transparent") && !click_state ){
      element.classList.toggle("transparent");
  }
  if (!click_state){
    toggle_colors_for_elements(document.getElementsByClassName('navbar-item'), window.scrollY > threshold);
    toggle_colors_for_elements([document.getElementById('navbar-brand')], window.scrollY > threshold);
    toggle_colors_for_elements(document.getElementsByClassName('navbar-button'), window.scrollY > threshold);
  }

}

function toggle_color() {
  const should_change = window.scrollY < threshold;
  if (should_change){
    let element = document.getElementById('navigation-bar');
    element.classList.toggle("transparent");
    toggle_colors_for_elements(document.getElementsByClassName('navbar-item'),  !click_state);
    toggle_colors_for_elements([document.getElementById('navbar-brand')],  !click_state);
    toggle_colors_for_elements(document.getElementsByClassName('navbar-button'),  !click_state);
  }
  click_state = !click_state;



}

</script>

<Router>
  <nav class="navbar navbar-expand-lg fixed-top transparent" id="navigation-bar">
    <div class="max-width-container">
      <a id="navbar-brand" href="/" class="black-text poppins"><b>Knew</b><span class="thinnest-text">Homes</span></a>
      <button on:click={toggle_color}
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
      >
      <span class="navbar-toggler-icon" />
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <div class="navbar-nav vertically-aligned">
        {#if $user === null}
        <a class="navbar-item poppins black-text thin-text" href="/login">Login</a>
        <a class="navbar-item poppins black-text thin-text" href="/signup">Sign Up</a>
        <a class="navbar-item poppins black-text thin-text" href="/about">About</a>
        <GetStartedButton classes="navbar-button tertiary-button black-text black-border thin-text" text="Speak With Us Now"/>
        {:else}
        <a class="navbar-item poppins black-text thin-text" href="/profile">My Documents</a>
        <a class="navbar-item poppins black-text thin-text" href="/about">About</a>
        <button on:click={logout} class="navbar-button tertiary-button black-text black-border thin-text ">Logout</button>
        {/if}
      </div>
    </div>
  </div>
</nav>

<Route path="/" component={Home} />
<Route path="/login" component={Login} />
<Route path="/signup" component={Signup} />
<Route path="/about" component={About} />
<Route path="/profile" component={UserProfile} />
<Route path="/password_recovery" component={PasswordRecovery} />
<!-- <Route path="/calendar" component={Calendar} /> -->
</Router>

<style>

/*
Dark Blue
#192655
25,38,85

Fade Blue
#5C6E91
rgb(92,110,145)

Lavender
#997EA1
rgb(153,126,161)

*/
  .navbar {
    padding: 1% 5% 1% 5%;
    background-color: rgba(92,110,145, 0.975);
    box-shadow: 0px 5px 25px 1px rgba(0,0,0, 0.5);
    transition: box-shadow 0.5s, background-color 0.5s;
  }

  #navbar-brand{
    width: 40%;
    max-width: 300px;
    margin: none;
    font-size: 1.75rem;
  }

  #navbarNav{
    width: 60%;
    max-width: 600px;
    padding: 0 auto;
    float:  right;
  }

  .navbar-toggler{
    background-color: #EEEDED;
  }

  .navbar-toggler{
    float: right;
  }

  .transparent {
    /*background-image: linear-gradient(rgba(25,38,85,1), rgba(255,0,0,0));*/
    background-color: transparent;
    box-shadow: none;
    transition: box-shadow 0.5s, background-color 0.5s;
  }

  /* Style for modern and clean buttons */
  .navbar-item{
    margin-right: 30px; /* Adds space between nav items */
    transition: scale 0.05s; /* Smooth transition for hover effect */
    font-size: 1.5vh;
    text-decoration: none;
  }

  .navbar-item:hover {
    scale: 1.05; /* Color change on hover */
  }

  .navbar-nav {
    margin-left: auto;
    margin-right: 0;
  }

  @media screen and (max-width: 600px) {

    .navbar-nav {
      float: right;
      text-align: right;
      margin-top: 10px;
    }

    .navbar-item{
      width: 100%;
      margin-right:  0px;
      text-align: right;
      padding-top: 1%;
    }
  }
</style>

